@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  font-family: 'Poppins', 'sans-serif' !important;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
}

.forgot-password {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
}

.reset-password {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
}

.gradient-background {
  background: radial-gradient(circle at 75% 15%, rgba(26, 22, 45, 0.75), rgba(0, 255, 0, 0) 50%),
    radial-gradient(circle at 15% 50%, rgba(26, 22, 45, 0.75), rgba(0, 0, 255, 0) 50%),
    radial-gradient(circle at 75% 85%, rgba(26, 22, 45, 0.75), rgba(0, 255, 0, 0) 50%) #0b3a4e;
}

.children > div:first-child {
  padding-left: 4rem;
  padding-right: 4rem;
  padding-top: 6.5rem;
  padding-bottom: 6rem;
}

.MuiDataGrid-columnHeader {
  outline: none !important;
  display: flex !important;
  align-items: center !important;
  line-height: normal !important;
}

.MuiDataGrid-columnHeaderTitle {
  font-weight: bold !important;
}
 
.MuiDataGrid-row {
  max-height: 100% !important;
  border-bottom: 1px solid rgba(224, 224, 224, 1) !important;
}

.MuiDataGrid-cell {
  outline: none !important;
  border-bottom: none !important;
  display: flex !important;
  align-items: center !important;
  line-height: normal !important;
  white-space: normal !important;
}

.MuiTextField-root {
  width: 100%;
}
.MuiTextField-root > * {
  height: 2.5rem !important;
}

.MuiSelect-select,
.MuiOutlinedInput-input {
  padding: 8px 14px !important;
}

.muiWhiteInput {
  color: white !important;
  border: 1px solid white !important;
  border-radius: 5px !important;
}

.muiWhiteInput * {
  color: white !important;
}

.muiWhiteInput > div:first-child {
  border: none !important;
}

.muiWhiteInput > div:first-child * {
  border: none !important;
}

.muiWhiteInput > input {
  border: none !important;
}

.muiWhiteInput > svg {
  border: none !important;
}

.muiWhiteInput > fieldset {
  border: none !important;
}

.MuiDialog-paperWidthSm {
  max-width: none !important;
}

.MuiDialogActions-spacing {
  justify-content: center !important;
  margin-bottom: 10px !important;
}

.MuiDialogActions-root>:not(:first-of-type) {
  margin-left: 100px !important;
}
